<template>
    <header class="app-header" :min="minmenu" :show="showSearch">
        <div class="top">
            <div class="container row-between">
                <h3>
                    <router-link v-if="!checkroutepublico()" to="/">Evaluación de la Gestión Municipal: mejora,
                        transparencia y rendición de cuentas
                    </router-link>
                    <router-link v-else :to="$t('enlaces.listadocartasserviciospublico')">Evaluación de la Gestión
                        Municipal: mejora, transparencia y rendición de cuentas
                    </router-link>
                </h3>
                <a href="https://www.madrid.es" target="_blank"><img src="/img/logo-madrid.png" alt="Madrid"></a>
                <button class="show-menu" @click="showmenu = !showmenu" title="Desplegar menú movil" :show="showmenu">
                    <span></span><span></span><span></span>

                    <i></i>
                    <i></i>

                </button>
            </div>
        </div>

        <div class="search-bar" :show="showSearch">
            <div class="container">
                <input type="text" placeholder="¿Qué estas buscando?" @keyup.esc="showSearch = false">
                <a href="" class="search"></a>
            </div>
        </div>

        <div class="bottom" :show="showSearch">
            <nav class="container row-start" :show="showmenu">
                <router-link v-if="!checkroutepublico()" to="/" class="home">Inicio</router-link>
                <div v-if="!checkroutepublico()" class="with-child">
                    <router-link :to="$t('enlaces.compromisos')">Compromisos de Gobierno</router-link>
                    <div class="childs">
                        <div class="childs-container">
                            <router-link :to="$t('enlaces.compromisos')">¿Cómo queremos que sea Madrid?</router-link>
                            <router-link :to="$t('enlaces.planoperativo')">¿Cómo vamos a conseguirlo?</router-link>
                            <router-link :to="$t('enlaces.indicecumplimiento')">Índice de cumplimiento</router-link>
                        </div>
                    </div>
                </div>
                <router-link v-if="!checkroutepublico()" :to="$t('enlaces.areasaccion')">Áreas de Acción</router-link>
                <router-link v-if="!checkroutepublico()" :to="$t('enlaces.areasgobierno')">Áreas de Gobierno, Distritos
                    y
                    Pleno
                </router-link>
                <!-- <router-link v-if="!checkroutepublico()" :to="$t('enlaces.distritos')">En los Distritos</router-link> -->
                <router-link v-if="!checkroutepublico()" :to="$t('enlaces.distritos')">Ámbito territorial</router-link>
                <router-link v-if="!checkroutepublico()" :to="$t('enlaces.listadocartasservicios')">Cartas de Servicios
                </router-link>
                <router-link v-else :to="$t('enlaces.listadocartasserviciospublico')">Cartas de Servicios</router-link>

                <!-- <router-link v-if="!checkroutepublico()" :to="$t('enlaces.acuerdosvilla')">Acuerdos de la
                    Villa</router-link> -->

                <a v-if="!checkroutepublico()" @click="showSearch = !showSearch" class="search"
                    :close="showSearch">Buscar</a>
                <router-link v-if="!checkroutepublico()" class="search" :to="$t('enlaces.buscador')">Buscar
                </router-link>
            </nav>
        </div>

    </header>
</template>


<script>
export default {
    name: 'app-header',
    mounted() {
        this.checkmenu();
        window.addEventListener('scroll', () => {
            window.requestAnimationFrame(() => {
                this.checkmenu();
            });
        });


    },
    data: () => ({
        showSearch: false,
        minmenu: false,
        showmenu: false
    }),
    methods: {
        checkmenu() {
            var position = window.scrollY;
            if (position > 100) {
                this.minmenu = true;
            } else if (position <= 100 && this.minmenu) {
                this.minmenu = false;
            }
        },
        checkroutepublico() {
            if (this.$route.path.includes('publico')) {
                return true;
            } else {
                return false;
            }
        }
    },
    watch: {
        '$route'() {
            this.showmenu = false;
        }
    }
}
</script>