<template>
    <div class="tabla-estrategia">



        <main>

            <!-- Agrego un custom total porque con menos de 10 elementos desaparece el pagindador  -->
            <span v-if="datafiltered.length <= 10" style="display: flex; align-content: start; margin-bottom: -20px;">
                Total: <strong style="font-weight: bold;">{{ datafiltered.length | dot }}</strong>
            </span>

            <div class="tabla">
                <v-client-table ref="table" :data="datafiltered" :columns="columns[type]" :options="options"
                    :notsearchable="!cansearch">
                    <span class="custom-td -actuacion" slot="actuacion" slot-scope="{ row }">
                        <span v-if="row.nombre">{{ row.nombre }}</span>
                        <span v-else>--</span>
                    </span>

                    <span class="custom-td -unidad-responsable" slot="unidadresponsable" slot-scope="{ row }">
                        <span v-if="row.unidadresponsable">{{ row.unidadresponsable }}</span>
                        <span v-else>--</span>
                    </span>

                    <span class="custom-td -compromiso-gobierno" slot="compromisodegobierno" slot-scope="{ row }">
                        <span class="compromiso-gobierno" v-if="row.compromisodegobierno">
                            <span :key="key"
                                v-for="(compromiso, key) in $options.filters.compromiso(row.compromisodegobierno)">{{
                                    compromiso[0] }}</span>

                            <a class="show" @click="showpopup"></a>

                            <div class="popup">
                                <span :key="key"
                                    v-for="(compromiso, key) in $options.filters.compromiso(row.compromisodegobierno)">
                                    {{ compromiso[1] }}
                                </span>
                            </div>

                        </span>
                        <span v-else>--</span>
                    </span>

                    <span class="custom-td" slot="areadeaccion" slot-scope="{ row }">
                        <span v-if="row.areadeaccion">{{ row.areadeaccion }}</span>
                        <span v-else>--</span>
                    </span>


                    <span class="custom-td" slot="distrito" slot-scope="{ row }">
                        <span v-if="row.distrito">{{ row.distrito }}</span>
                        <span v-else>--</span>
                    </span>

                    <span class="custom-td" slot="objetivo" slot-scope="{ row }">
                        <span v-if="row.idobjetivo">{{ objetivo(row.idobjetivo).nombre }}</span>
                        <span v-else>--</span>
                    </span>

                    <span class="custom-td" slot="eje" slot-scope="{ row }">
                        <span v-if="row.ideje">{{ eje(row.ideje).nombre }}</span>
                        <span v-else>--</span>
                    </span>

                    <span class="custom-td" slot="areagobierno" slot-scope="{ row }">
                        <span v-if="row.areagobierno">{{ row.areagobierno }}</span>
                        <span v-else>--</span>
                    </span>


                    <!-- <span class="custom-td row-between" slot="cumplimiento" slot-scope="{ row }">
                        <span class="mini-progress-bar" v-if="row.tipo == 'Valorativo'"
                            :title="`Grado de cumplimiento: ${row.cumplimiento}%`">
                            <span class="progress" :full="row.cumplimiento == 100"
                                :style="`width:${row.cumplimiento}%`"></span>
                        </span>
                        <figure v-if="row.tipo == 'Valorativo'">{{ row.cumplimiento }}%</figure>
                    </span> -->

                    <!-- Cuando el tipo de indicador es valorativo y el cumplimiento es diferente de null, se muestra el porcentaje de cumplimiento  - 2024-11-04-->
                    <span class="custom-td row-between" slot="cumplimiento" slot-scope="{ row }">
                        <span class="mini-progress-bar" v-if="row.tipo == 'Valorativo' && row.cumplimiento !== null"
                            :title="`Grado de cumplimiento: ${row.cumplimiento}%`">
                            <span class="progress" :full="row.cumplimiento == 100"
                                :style="`width:${row.cumplimiento}%`"></span>
                        </span>
                        <figure v-if="row.tipo == 'Valorativo' && row.cumplimiento !== null">{{ row.cumplimiento }}%</figure>
                    </span>

                    <span class="custom-td" slot="ods" slot-scope="{ row }">
                        <span class="ods" :data="'ods-' + row.ods" v-if="row.ods">{{ row.ods }}</span>
                        <span v-else></span>
                    </span>

                    <span class="custom-td" slot="tipoindicador" slot-scope="{ row }">
                        <span v-if="row.carta == 1">Carta de servicio</span>
                        <span v-if="row.Pe == 1">Plan Estratégico</span>
                        <span v-if="row.Pog == 1">Plan Operativo</span>
                        <span v-if="row.Pogevaluacion == 1">Evaluación</span>
                        <span v-if="row.Poginformativo == 1">Informativo</span>
                    </span>

                    <span class="custom-td" slot="enlace" slot-scope="{ row }">
                        <router-link v-if="row.id && !checkroutepublico()" class="link" origen="indicador"
                            :to="$t('enlaces.indicador') + '/' + row.id">Ver indicador
                        </router-link>
                        <router-link v-if="row.indicadorid && !checkroutepublico()" class="link" origen="indicador"
                            :to="$t('enlaces.indicador') + '/' + row.indicadorid">Ver indicador
                        </router-link>
                        <router-link v-if="row.indicadorid && checkroutepublico()" class="link" origen="indicador"
                            :to="$t('enlaces.indicadorpublico') + '/' + row.indicadorid">Ver indicador
                        </router-link>
                    </span>

                    <span class="custom-td -status" slot="Situacion" slot-scope="{ row }">
                        <span class="status" :status="'status-' + row.idactuacioncumplimiento"
                            :title="cumplimiento(row.idactuacioncumplimiento).nombre">{{
                                cumplimiento(row.idactuacioncumplimiento).nombre
                                | estatus }}</span>
                    </span>




                    <template v-if="type == 'actuaciones'">
                        <div class="estados" slot="afterFilter" v-if="$route.name != 'buscador'">
                            <div class="contenedor-input -full -m0">
                                <multiselect v-model="filter.status" :options="dominios.fakecumplimiento"
                                    placeholder="Estado" label="nombre" :multiple="true" :closeOnSelect="false"
                                    :tagPosition="'bottom'" :showLabels="false" :showNoResults="false" track-by="nombre"
                                    :searchable="false">
                                </multiselect>

                            </div>
                        </div>

                        /** Oculto compromiso combo hidden*/
                        <div class="estados" slot="afterFilter" hidden>
                            <div class="contenedor-input -full -m0">
                                <multiselect v-model="filter.compromiso" :options="compromisos"
                                    placeholder="Compromiso de Gobierno" :multiple="true" :closeOnSelect="false"
                                    :tagPosition="'bottom'" :showLabels="false" :showNoResults="false" :searchable="false">
                                </multiselect>

                            </div>

                        </div>

                        <div class="estados" slot="afterFilter"
                            v-if="$route.name != 'distritos' && $route.name != 'buscador'">
                            <div class="contenedor-input -full -m0">
                                <multiselect v-model="filter.distritos" :options="dominios.distritos"
                                    placeholder="Ámbito territorial" label="nombre" :multiple="true" :closeOnSelect="false"
                                    :tagPosition="'bottom'" :showLabels="false" :showNoResults="false" track-by="nombre">
                                </multiselect>
                            </div>
                        </div>
                    </template>


                    <div class="estados" slot="afterFilter"
                        v-if="$route.name != 'areas-gobierno' && $route.name != 'ficha-cartas-servicios' && $route.name != 'buscador'">
                        <div class="contenedor-input -full -m0">
                            <multiselect v-model="filter.areas_gobierno" :options="dominios.areas_gobierno"
                                placeholder="Áreas de Gobierno, Distritos..." label="nombre" :multiple="true"
                                :closeOnSelect="false" :tagPosition="'bottom'" :showLabels="false" :showNoResults="false"
                                track-by="nombre">
                            </multiselect>
                        </div>
                    </div>

                    <div class="estados" slot="afterFilter"
                        v-if="$route.name != 'areas-accion' && $route.name != 'ficha-cartas-servicios' && $route.name != 'buscador'">
                        <div class="contenedor-input -full -m0">

                            <div class="contenedor-input -full -m0" v-if="dominios.areas_accion">
                                <multiselect v-model="filter.areas_accion" :options="dominios.areas_accion"
                                    placeholder="Áreas de Acción" label="nombre" :multiple="true" :closeOnSelect="false"
                                    :tagPosition="'bottom'" :showLabels="false" :showNoResults="false" track-by="nombre">
                                </multiselect>
                            </div>
                        </div>
                    </div>

                    <template v-if="type == 'indicadores'">
                        <!-- Combo de tipo de indicadores , visible para areas-accion y areas-gobierno -->
                        <div class="estados" slot="afterFilter"
                            v-if="$route.name == 'areas-accion' || $route.name == 'areas-gobierno'">
                            <div class="contenedor-input -full -m0">
                                <div class="contenedor-input -full -m0" v-if="dominios.indicadores">
                                    <multiselect v-model="filter.tipodeindicadores" :options="tipodeindicadoreslista"
                                        placeholder="Tipo de indicador" :multiple="true" :closeOnSelect="false"
                                        label="nombre" :tagPosition="'bottom'" :showLabels="false" :showNoResults="false"
                                        track-by="nombre">
                                    </multiselect>
                                </div>
                            </div>
                        </div>
                    </template>


                    <span class="length" slot="beforeLimit">
                        Total: <strong>{{ datafiltered.length | dot }}</strong>
                    </span>



                </v-client-table>



            </div>

        </main>

    </div>
</template>


<script>

import { mapGetters } from 'vuex';

export default {
    name: 'resultados',
    props: ['data', 'cansearch', 'type'],
    mounted() {
        if (this.$route.name == 'ficha-cartas-servicios') {
            this.columns['indicadores'].push('cumplimiento')
        }
        this.columns['indicadores'].push('enlace')

        /** Lleno compromisos con el array compromisosgobierno.nombre */
        this.compromisos = this.compromisosgobierno.map(item => item.nombre)
        //console.log("this.compromisosgobierno", this.compromisosgobierno);

        //console.log(this.actuaciones);
    },
    data: () => ({
        columns: {
            'actuaciones': [
                "actuacion",
                "unidadresponsable",
                //"compromisodegobierno", /** Oculto compromiso columna */
                "distrito",
                "Situacion",
            ],
            'indicadores': [
                'nombre',
            ],
        },

        /** Se llena automáticamente al inicio */
        compromisos: [],
        tipodeindicadoreslista: [
            { id: "estrategico", nombre: "Indicador del Plan Estratégico/Estratégico" },
            { id: "accion", nombre: "Indicador del Plan Estratégico/De acción" },
            { id: "sectorial", nombre: "Indicador del Plan Estratégico/Sectorial" }
        ],
        // compromisos: [
        //      'Acuerdos de la Villa',
        //      'Acuerdo de Gobierno'
        // ],
        options: {
            skin: 'table',
            highlightMatches: true,
            headings: {
                "actuacion": "Actuación",
                "unidadresponsable": "Unidad responsable",
                "compromisodegobierno": "Compromiso de Gobierno",
                "distrito": "Ámbito territorial",
                "ods": "ODS",
                "Situacion": "Situación",
                "areadeaccion": "Área de Acción",
                "areagobierno": "Unidad Responsable",
                'eje': "Eje",
                'tipoindicador': "Tipo de Indicador",
                'enlace': "Enlace",
                'cumplimiento': 'Grado de Cumplimiento'
            },
            texts: {
                filter: "Buscar:",
                filterBy: 'Filtrar por {column}',
                count: '',
                records: 'Resultados',
                limit: "Por Página: ",
                page: "Págína:",
                noResults: "No se han encontrado resultados",
                filterPlaceholder: "Escribe para buscar",
            },
            dateFormat: 'DD-MM-YY',
            dateColumns: ['updated_at'],
            resizableColumns: true,
            filterable: [
                "actuacion",
                "unidadresponsable",
                "compromisodegobierno",
                "distrito",
                "Situacion",
                'nombre',
                'tipoindicador',
                'areagobierno',
                'areadeaccion',
                'cumplimiento',
            ],
            pagination: {
                show: true,
            },
            perPage: 50,
            sortable: [
                'nombre',
                "unidadresponsable",
                "actuacion",
                "compromisodegobierno",
                "distrito",
                'tipoindicador',
                'areagobierno',
                'areadeaccion',
                'cumplimiento',
                "Situacion"
            ],
            customSorting: {
                Situacion(ascending) {
                    return (a, b) => {
                        if (ascending) {
                            return a.idactuacioncumplimiento >= b.idactuacioncumplimiento ? 1 : -1;
                        } else {
                            return a.idactuacioncumplimiento <= b.idactuacioncumplimiento ? 1 : -1;
                        }
                    }
                },
                cumplimiento(ascending) {
                    return (a, b) => {

                        if (ascending) {
                            return a.cumplimiento >= b.cumplimiento ? 1 : -1;
                        } else {
                            return a.cumplimiento <= b.cumplimiento ? 1 : -1;
                        }
                    }
                },
                /* Ordena por areagobierno  sin tener en cuenta los acentos */
                unidadresponsable(ascending) {
                    //console.log("ingreso a ordenar")
                    return (a, b) => {
                        if (ascending) {
                            return a.unidadresponsable.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") >= b.unidadresponsable.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") ? 1 : -1;
                        } else {
                            return a.unidadresponsable.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") <= b.unidadresponsable.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") ? 1 : -1;
                        }
                    }
                },
                /* Ordena por distrito  sin tener en cuenta los acentos */
                distrito(ascending) {
                    return (a, b) => {
                        if (ascending) {
                            return a.distrito.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") >= b.distrito.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") ? 1 : -1;
                        } else {
                            return a.distrito.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") <= b.distrito.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") ? 1 : -1;
                        }
                    }
                },

                /* Ordena por nombre  sin tener en cuenta los acentos */
                nombre(ascending) {
                    return (a, b) => {
                        if (ascending) {
                            return a.nombre.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") >= b.nombre.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") ? 1 : -1;
                        } else {
                            return a.nombre.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") <= b.nombre.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") ? 1 : -1;
                        }
                    }
                },
            },

            sortIcon: {
                is: 'glyphicon-sort',
                base: 'glyphicon',
                up: 'order-up',
                down: 'order-down'
            },
            rowClassCallback(row) {
                let lastParam = window.location.pathname.split("/").pop();
                return (row.idPractica == lastParam) ? 'selected' : '';
            }
        },
        filter: {
            status: '',
            compromiso: '',
            distritos: [],
            areas_gobierno: [],
            areas_accion: [],
            tipodeindicadores: [],
        }
    }),
    methods: {
        showpopup(event) {
            event.target.classList.toggle('open');
            event.target.nextSibling.classList.toggle('open');
        },
        checkroutepublico() {
            if (this.$route.path.includes('publico')) {
                return true;
            } else {
                return false;
            }
        }
    },
    computed: {
        ...mapGetters({
            cumplimiento: 'getIdCumplimiento',
            eje: 'getEje',
            objetivo: 'getObjetivo',
            dominios: 'getDominios',
            compromisosgobierno: 'getCompromisosGobierno',
        }),
        datafiltered() {
            let data = JSON.parse(JSON.stringify(this.data));


            //ESTADO
            if (this.filter.status.length != 0) {
                let status = this.filter.status.map(e => e.id);
                data = data.filter(actuacion => {
                    return (status.some(estatus => (estatus == actuacion.idactuacioncumplimiento) || (estatus == 3 && actuacion.idactuacioncumplimiento >= 3)));
                })
            }

            //DISTRITOS
            if (this.filter.distritos.length != 0) {
                let distritos = this.filter.distritos.map(e => e.id);
                // data = data.filter(actuacion => {
                //     return (distritos.some(distrito => distrito == actuacion.iddistrito));
                // })

                data = data.filter(actuacion => {
                    /** Hago un split de iddistrito y le saco los espacios*/
                    let distritos_actuacion = actuacion.iddistrito.split(",").map(e => e.trim());

                    return distritos.some(distrito => distritos_actuacion.includes(distrito.toString()));
                })

            }


            //COMPROMISO
            if (this.filter.compromiso.length != 0) {
                data = data.filter(actuacion => {
                    return (this.filter.compromiso.some(compromiso => !!actuacion.compromisodegobierno && actuacion.compromisodegobierno.includes(compromiso)));
                })
            }

            //ÁREAS GOBIERNO
            if (this.filter.areas_gobierno.length != 0) {
                //console.log("ingresa a areas_gobierno")
                let areas_gobierno = this.filter.areas_gobierno.map(e => e.id);
                data = data.filter(actuacion => {
                    return (areas_gobierno.some(area => area == actuacion.idareagobierno));
                })
            }

            //ÁREAS ACCION
            if (this.filter.areas_accion.length != 0) {
                let areas_accion = this.filter.areas_accion.map(e => e.id);
                data = data.filter(actuacion => {
                    return (areas_accion.some(area => (area == actuacion.idareaaccion || area == actuacion.idareadeaccion)));
                })
            }


            //TIPO INDICADOR el filtro se realiza con tipoindicadores.id
            if (this.filter.tipodeindicadores.length != 0) {
                let tipodeindicadores = this.filter.tipodeindicadores.map(e => e.id);

                let tipodeindicadores_estrategico = tipodeindicadores.includes("estrategico") ? 1 : null;
                let tipodeindicadores_accion = tipodeindicadores.includes("accion") ? 1 : null;
                let tipodeindicadores_sectorial = tipodeindicadores.includes("sectorial") ? 1 : null;

                data = data.filter(actuacion => {
                    return (tipodeindicadores_estrategico == actuacion.estrategico || tipodeindicadores_accion == actuacion.accion || tipodeindicadores_sectorial == actuacion.sectorial);
                })

            }



            /** Devuelvo ordenado por nombre sin tener en cuenta los acentos */
            data = data.sort((a, b) => {
                return a.nombre.localeCompare(b.nombre, 'es', { sensitivity: 'base' });
            });

            //console.log("resultados filtrados", data);

            return data;
        },

    },
    filters: {
        compromiso(value) {
            let compromisos = value.split("#");
            compromisos = compromisos.map(c => c.split(":"));
            return compromisos;
        },
        estatus(value) {
            let estatus = {
                'Iniciada': "En ejecución",
                "Restrasada en inicio": "No iniciada",
                "Planificada a futuro": "No iniciada",
                "Pendiente de planificacion": "No iniciada",
                "Terminada": "Terminada"
            }

            return estatus[value];
        },
        dot(value) {
            return new Intl.NumberFormat('es-VE').format(Number(value));
        }
    }
}
</script>