<template>
    <footer class="app-footer -section">
        <div class="top">
            <div class="container row-start">
                <div class="texto">
                    <p>
                        <router-link v-if="$route.name != 'Home' && !checkroutepublico()" to="/">Evaluación de la
                            Gestión
                            Municipal: mejora,
                            transparencia y rendición de cuentas.</router-link>
                        <a v-else @click="toUp">Evaluación de la Gestión Municipal: mejora, transparencia y rendición de
                            cuentas.</a>
                    </p>

                </div>
                <nav class="links column-center">
                    <router-link v-if="!checkroutepublico()" :to="$t('enlaces.compromisos')">Plan Estratégico
                    </router-link>
                    <router-link v-if="!checkroutepublico()" :to="$t('enlaces.planoperativo')">Plan Operativo de
                        Gobierno
                    </router-link>
                    <router-link v-if="!checkroutepublico()" :to="$t('enlaces.areasaccion')">Áreas de Acción
                    </router-link>
                    <router-link v-if="!checkroutepublico()" :to="$t('enlaces.areasgobierno')">Áreas de Gobierno,
                        Distritos y Pleno</router-link>
                    <router-link v-if="!checkroutepublico()" :to="$t('enlaces.distritos')">Ámbito territorial
                    </router-link>
                    <router-link v-if="!checkroutepublico()" :to="$t('enlaces.listadocartasservicios')">Cartas de
                        Servicios</router-link>
                    <!-- <router-link v-if="!checkroutepublico()" :to="$t('enlaces.acuerdosvilla')">Acuerdos de la Villa
                    </router-link> -->

                </nav>

                <nav class="others-links column-start">
                    <h4>Otros espacios web del Ayuntamiento de Madrid</h4>
                    <a target="_blank" href="https://transparencia.madrid.es/">transparencia.madrid.es</a>
                    <a target="_blank" href="https://datos.madrid.es/">datos.madrid.es</a>
                    <a target="_blank" href="https://decide.madrid.es/">decide.madrid.es</a>
                    <a target="_blank" href="https://www.madrid.es/">www.madrid.es</a>
                    <a target="_blank"
                        href="https://www.madrid.es/portales/munimadrid/es/Inicio/El-Ayuntamiento/Observatorio-de-la-Ciudad/?vgnextfmt=default&vgnextchannel=ec38d941c9b25710VgnVCM2000001f4a900aRCRD">Observatorio
                        de la Ciudad</a>
                </nav>

            </div>
        </div>
        <div class="bottom">
            <nav class="row-start container">
                <a target="_blank" href="https://www.madrid.es/">Ayuntamiento de Madrid 2022</a>
                <a target="_blank"
                    href="https://www.madrid.es/portales/munimadrid/es/Inicio/Accesibilidad?vgnextfmt=default&vgnextchannel=1ade43db40317010VgnVCM100000dc0ca8c0RCRD">Accesibilidad</a>
                <a target="_blank"
                    href="https://www.madrid.es/portales/munimadrid/es/Inicio/Aviso-Legal/Aviso-legal?vgnextfmt=default&vgnextoid=ce3e1e7b0f578010VgnVCM100000dc0ca8c0RCRD&vgnextchannel=8a0f43db40317010VgnVCM100000dc0ca8c0RCRD">Aviso
                    Legal</a>
                <a target="_blank"
                    href="https://www.madrid.es/portales/munimadrid/es/Inicio/Proteccion-de-datos?vgnextfmt=default&vgnextoid=2c241e7b0f578010VgnVCM100000dc0ca8c0RCRD&vgnextchannel=251f43db40317010VgnVCM100000dc0ca8c0RCRD ">Protección
                    de Datos</a>
            </nav>
        </div>

        <nav class="arrows">
            <a class="to-down" @click="down">Sección anterior</a>
            <a class="to-top" @click="up">Siguiente sección</a>
        </nav>

    </footer>
</template>



<script>
export default {
    name: 'app-footer',
    methods: {
        up() {
            let position = window.scrollY;
            var selected;
            var actual = -10000;
            var els = document.getElementsByClassName("-section");
            Array.prototype.forEach.call(els, (el) => {
                let diference = this.offset(el) - position;

                console.log(diference);

                if (diference < -135 && diference > actual) {
                    selected = el;
                    actual = diference;
                }
            });

            console.log("-------")

            if (selected) {
                scroll({
                    top: this.offset(selected) - 135,
                    behavior: "smooth"
                });
            }
        },
        down() {
            let position = window.scrollY;
            var selected;
            var els = document.getElementsByClassName("-section");
            Array.prototype.forEach.call(els, (el) => {
                let diference = this.offset(el) - position;
                if (diference > 135 & !selected) {
                    selected = el;
                }
            });

            if (selected) {
                scroll({
                    top: this.offset(selected) - 135,
                    behavior: "smooth"
                });
            }

        },
        toUp() {
            scroll({
                top: 0,
                behavior: "smooth"
            });
        },
        offset(el) {
            var rect = el.getBoundingClientRect(),
                scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            return rect.top + scrollTop
        },
        checkroutepublico() {
            if (this.$route.path.includes('publico')) {
                return true;
            } else {
                return false;
            }
        }
    }
}
</script>