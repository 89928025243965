<template>
  <div id="app">
    <app-header></app-header>
    <router-view class="margin-menu" />
    <app-footer></app-footer>
  </div>
</template>


<script>

import header from '@/components/header';
import footer from '@/components/footer';

import { mapActions } from 'vuex'

export default {
  name: 'app',
  data() {
    return {
      isRouteChecked: false,  // Nueva bandera para controlar la carga inicial
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.checkRoute(to.path);
    });
  },
  mounted() {
    this.$nextTick(() => {
      this.requestHomeData();
      this.checkRoute();
    });
  },
  watch: {
    '$route.path'(newPath) {
      console.log('Ruta cambiada a:', newPath);
      this.checkRoute();
    }
  },
  methods: {
    checkRoute() {
      // Si es la ruta raiz, cargo requestDominios, sino requestCartas si tiene la palabra cartas
      if (!this.isRouteChecked) {
        if (this.$route.path.includes('/sistema-de-cartas-de-servicios/cartas/')) { 
          // Si es una carta
        }else if(this.$route.path.includes('/sistema-de-cartas-de-servicios/cartas')) { 
          // Si es la lista de cartas
          console.log('requestCartas...');
          this.requestCartas();
        } else {

          if (this.$route.path === '/') {          
            // Si es la ruta raiz
          } else {
            this.isRouteChecked = true;
          }

          if (this.isRouteChecked) {
            console.log('requestDominios...');
            this.requestDominios();
          }
        }


      }
    },
    ...mapActions([
      'requestHomeData',
      'requestCartas',
      'requestDominios'

    ])
  },
  components: {
    'app-header': header,
    'app-footer': footer,
  }
}
</script>

<style lang="scss">
@import '@/assets/css/colors.scss';
@import '@/assets/css/style.scss';
@import '@/assets/css/responsive.scss';
// @import url('https://unpkg.com/vue-multiselect@2.1.6/dist/vue-multiselect.min.css');
@import '~vue-multiselect/dist/vue-multiselect.min.css';
</style>