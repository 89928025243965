import axios from 'axios';

const datos = {
    state: () => ({
        homedata: '',
        homecartas: '',
        dominios: {
            'cartas': [],
            "ejes": [],
            "areas_gobierno": [],
            'distritos': [],
            "areas_accion": [],
            "cumplimiento": [],
            "objetivos": [],
            "indicadores": [],
            "actuaciones": [],
            "proyectos": [],

            'compromisos': [],
            'indicadoresCartas': [],
            'areas_mejora': [],
            'fakecumplimiento': [
                { "id": 2, "nombre": "En ejecución" },
                { "id": 3, "nombre": "No iniciada" },
                { "id": 1, "nombre": "Terminada" },
            ],
            'fakecumplimientoaavv': [
                { "id": 2, "nombre": "En ejecución" },
                { "id": 3, "nombre": "No iniciado" },
                { "id": 1, "nombre": "Terminado" },
            ],
            'compromisos_gobierno': [],
            'planes': [],
            // 'acuerdos_villa': [],
            // 'acuerdos_villa_propuesta': [],
            // 'acuerdos_villa_mesa': [],
            // 'acuerdos_villa_grupo': [],
        }
    }),
    getters: {
        getHomedata: state => state.homedata,
        getHomecartas: state => state.homecartas,
        getDominios: state => state.dominios,
        getActuaciones: state => state.dominios.actuaciones,
        getIndicadores: state => state.dominios.indicadores,
        getIndicadoresCartas: state => state.dominios.indicadoresCartas,
        getCompromisosGobierno: state => state.dominios.compromisos_gobierno,
        getPlanes: state => state.dominios.planes,
        getAcuerdosVilla: state => state.dominios.acuerdos_villa,
        getAcuerdosVillaPropuesta: state => state.dominios.acuerdos_villa_propuesta,
        getAcuerdosVillaMesa: state => state.dominios.acuerdos_villa_mesa,
        getAcuerdosVillaGrupo: state => state.dominios.acuerdos_villa_grupo,
        getObjetivos(state) {
            return (eje) => {
                return state.dominios.objetivos.filter(obj => obj.ideje == eje);
            };
        },
        getEjes(state) {
            return (ejePamam) => {
                /** Get indicadores By eje in some split ideje2 */
                let indicadoresEjes = state.dominios.indicadores.filter(indicador => indicador.ideje2 ? indicador.ideje2.split(',').some(i => i == ejePamam) : false);

                /** Filter when idobjetivo = 0 => only get indicadores transversales*/
                indicadoresEjes = indicadoresEjes.filter(indicador => indicador.idobjetivo == 0);

                /** If indicadoresEjes > 0 return from ejes the eje selected, else return empty */
                return indicadoresEjes.length > 0 ? state.dominios.ejes.filter(eje => eje.id == ejePamam) : [];
            };
        },
        getIndicadoresByObj(state) {
            return (obj) => {
                return state.dominios.indicadoresejes.filter(indicador => indicador.idobjetivo == obj);
            };
        },
        getIndicadoresByEje(state) {
            return (eje) => {
                return state.dominios.indicadoresejes.filter(indicador => indicador.ideje == eje && indicador.idobjetivo == 0);
            };
        },
        getActuacionesByAreaAccion(state) {
            return (area) => {
                //return state.dominios.actuaciones.filter(actuacion => actuacion.idareaaccion.includes(area));
                return state.dominios.actuaciones.filter(actuacion => actuacion.idareaaccion ? actuacion.idareaaccion.includes(area) : false);
            };
        },

        getIndicadoresByAreaAccion(state) {
            return (area) => {
                //return state.dominios.indicadores.filter(indicador => indicador.idareadeaccion == area);
                return state.dominios.indicadores.filter(indicador => indicador.idareadeaccion2 ? indicador.idareadeaccion2.includes(area) : false);
            };
        },
        getCartasByAreaAccion(state) {
            return (area) => {
                return state.dominios.cartas.filter(carta => carta.areadeaccionid == area);
            };
        },
        getActuacionesByAreaMunicipal(state) {
            return (area) => {
                return state.dominios.actuaciones.filter(actuacion => actuacion.idareagobierno == area);
            };
        },
        getIndicadoresByAreaMunicipal(state) {
            return (area) => {
                //return state.dominios.indicadores.filter(indicador => indicador.idareagobierno == area && indicador.Pe == 1);  /** No tiene en cuenta sectoriales */
                return state.dominios.indicadores.filter(indicador => indicador.idareagobierno == area && (indicador.Pe == 1 || indicador.sectorial == 1)); /** Tiene en cuenta sectoriales */
            };
        },
        getCartasByAreaMunicipal(state) {
            return (area) => {
                /* carta.areagobiernoid es un array separado por punto y coma */
                return state.dominios.cartas.filter(carta => carta.areagobiernoid.split(';').some(i => i == area));
                //return state.dominios.cartas.filter(carta => carta.areagobiernoid == area);
            };
        },
        getIndicadoresByCarta(state) {
            return (id) => {
                return state.dominios.indicadoresCartas.filter(indicador => indicador.cartaid == id);
            };
        },
        getIndicadoresbyCompromiso(state) {
            return (id) => {
                return state.dominios.indicadoresCartas.filter(compromiso => compromiso.compromisoid == id);
            };
        },
        getActuacionesByDistrito(state) {
            return (area) => {
                //return state.dominios.actuaciones.filter(actuacion => actuacion.iddistrito.split(',').some(i => i == area));
                let actuacionesConDistrito = state.dominios.actuaciones.filter(actuacion => actuacion.iddistrito != null);
                return actuacionesConDistrito.filter(actuacion => actuacion.iddistrito.split(',').some(i => i == area));
            };
        },
        getActuacionesByProyecto(state) {
            return (proyecto) => {
                return state.dominios.actuaciones.filter(actuacion => actuacion.idproyecto == proyecto);
            };
        },
        getIndicadoresByDistrito(state) {
            return (area) => {
                return state.dominios.indicadores.filter(indicador => indicador.iddistrito == area);
            };
        },
        getIdCumplimiento(state) {
            return (id) => {
                return state.dominios.cumplimiento.filter(el => el.id == id)[0];
            };
        },
        getProyectosByObjetivo(state) {
            return (objetivo) => {
                return state.dominios.proyectos.filter(proyecto => proyecto.idobjetivo == objetivo);
            };
        },

        getEje(state) {
            return (id) => {
                return state.dominios.ejes.filter(proyecto => proyecto.id == id)[0];
            };
        },
        getObjetivo(state) {
            return (id) => {
                return state.dominios.objetivos.filter(objetivo => objetivo.id == id)[0];
            };
        },
        getCarta(state) {
            return (id) => {
                return state.dominios.cartas.filter(carta => carta.cartaid == id)[0];
            };
        },
        getCompromisobyCarta(state) {
            return (id) => {
                return state.dominios.compromisos.filter(compromiso => compromiso.cartaid == id);
            };
        },

        getActuacionesByMesaAAVV(state) {
            return (area) => {
                // Si area = 0 => devuelve todas las actuaciones, si no devuelve las que pertenecen a la mesa
                if (area == 0) {
                    return state.dominios.acuerdos_villa_propuesta;
                } else {
                    return state.dominios.acuerdos_villa_propuesta.filter(acuerdos_villa_propuesta => acuerdos_villa_propuesta.idmesa == area);
                }
                //return state.dominios.acuerdos_villa.filter(actuaciones => acuerdos_villa.idmesa ? actuaciones.idmesa.includes(area) : false);
            };
        },
        getActuacionesByGrupoAAVV(state) {
            return (area) => {
                return state.dominios.acuerdos_villa_propuesta.filter(acuerdos_villa_propuesta => acuerdos_villa_propuesta.idgrupo == area);
            };
        },
        getAcuerdosVillaGrupoByMesa(state) {
            // Si mesa = 0 => devuelve todos los grupos, si no devuelve los que pertenecen a la mesa
            return (mesa) => {
                if (mesa == 0) {
                    return state.dominios.acuerdos_villa_grupo;
                } else {
                    return state.dominios.acuerdos_villa_grupo.filter(acuerdos_villa_grupo => acuerdos_villa_grupo.idmesa == mesa);
                }
            }
        },




    },
    actions: {

        requestHomeData({ commit }) {
            /** Cambio producción */
            axios.defaults.baseURL = (location.hostname == 'iamappprefront.iformalia.es') ? 'https://iamappipre.iformalia.es/InnoWSPublico.asmx/' : 'https://iamappi.iformalia.es/InnoWSPublico.asmx/'
            ////axios.defaults.baseURL = 'http://localhost:50351/InnoWSPublico.asmx'

            return new Promise((resolve, reject) => {
                axios.post('RequestHome').then(response => {
                    commit('setHomeData', response.data[0])
                }).catch(function (error) {
                    reject(error);
                });

                axios.post('RequestHomeCartas').then(response => {
                    commit('setHomeCartas', response.data[0])
                }).catch(function (error) {
                    reject(error);
                });
            });
        },

        // requestCartas solo carga las cartas
        requestCartas({ commit }) {
            /** Cambio producción */
            axios.defaults.baseURL = (location.hostname == 'iamappprefront.iformalia.es') ? 'https://iamappipre.iformalia.es/InnoWSPublico.asmx/' : 'https://iamappi.iformalia.es/InnoWSPublico.asmx/'
            ////axios.defaults.baseURL = 'http://localhost:50351/InnoWSPublico.asmx'

            return new Promise((resolve, reject) => {
                axios.post('RequestCartas').then(response => {
                    commit('setDominios', ['cartas', response.data])
                    resolve(response.data)
                }).catch(function (error) {
                    reject(error);
                });
            });
        },

        // requestCartas para una sola carta específica
        requestCarta({ commit }, id) {
            return new Promise((resolve, reject) => {
                axios.post('RequestCarta', { eParamId: id }).then(response => {
                    resolve(response.data[0])
                }).catch(function (error) {
                    reject(error);
                    commit('null');
                });
            });
        },

        requestCompromisos({ commit }) {
            /** Cambio producción */
            axios.defaults.baseURL = (location.hostname == 'iamappprefront.iformalia.es') ? 'https://iamappipre.iformalia.es/InnoWSPublico.asmx/' : 'https://iamappi.iformalia.es/InnoWSPublico.asmx/'
            ////axios.defaults.baseURL = 'http://localhost:50351/InnoWSPublico.asmx'

            return new Promise((resolve, reject) => {
                axios.post('RequestCompromisos').then(response => {
                    commit('setDominios', ['compromisos', response.data])
                    resolve(response.data)
                }).catch(function (error) {
                    reject(error);
                });
            });
        },

        requestIndicadoresCartas({ commit }) {
            /** Cambio producción */
            axios.defaults.baseURL = (location.hostname == 'iamappprefront.iformalia.es') ? 'https://iamappipre.iformalia.es/InnoWSPublico.asmx/' : 'https://iamappi.iformalia.es/InnoWSPublico.asmx/'
            ////axios.defaults.baseURL = 'http://localhost:50351/InnoWSPublico.asmx'

            return new Promise((resolve, reject) => {
                axios.post('RequestIndicadoresCartas').then(response => {
                    commit('setDominios', ['indicadoresCartas', response.data])
                    resolve(response.data)
                }).catch(function (error) {
                    reject(error);
                });
            });
        },

        //areas_accion
        requestAreasAccion({ commit }) {
            return new Promise((resolve, reject) => {
                axios.post('RequestAreasAccion').then(response => {
                    commit('setDominios', ['areas_accion', response.data])
                    resolve(response.data)
                }).catch(function (error) {
                    reject(error);
                });
            });
        },

        //areas_gobierno
        requestAreasGobierno({ commit }) {
            return new Promise((resolve, reject) => {
                axios.post('RequestAreasGobierno').then(response => {
                    commit('setDominios', ['areas_gobierno', response.data])
                    resolve(response.data)
                }).catch(function (error) {
                    reject(error);
                });
            });
        },

        requestDominios({ commit }) {
            /** Cambio producción */
            axios.defaults.baseURL = (location.hostname == 'iamappprefront.iformalia.es') ? 'https://iamappipre.iformalia.es/InnoWSPublico.asmx/' : 'https://iamappi.iformalia.es/InnoWSPublico.asmx/'
            ////axios.defaults.baseURL = 'http://localhost:50351/InnoWSPublico.asmx'

            let calls = {
                "cartas": "RequestCartas",
                "ejes": 'RequestEjes',
                "areas_gobierno": 'RequestAreasGobierno',
                'distritos': "RequestDistritos",
                "areas_accion": "RequestAreasAccion",
                "cumplimiento": "RequestActuacionesCumplimiento",
                "objetivos": "RequestObjetivos",
                "indicadores": "RequestIndicadores",
                "actuaciones": "RequestActuaciones",
                "proyectos": "RequestProyectos",

                "compromisos": "RequestCompromisos",
                'indicadoresCartas': "RequestIndicadoresCartas",
                // 'areas_mejora' : 'RequestAreasDeMejora',
                'indicadoresejes': 'RequestIndicadoresEjes',
                'compromisos_gobierno': 'RequestCompromisosGobierno',
                'planes': 'RequestPlanes',
                //          'acuerdos_villa': 'RequestAcuerdosVilla',
                //          'acuerdos_villa_propuesta': 'RequestAcuerdosVillaPropuesta',
                //          'acuerdos_villa_mesa': 'RequestAcuerdosVillaMesa',
                //         'acuerdos_villa_grupo': 'RequestAcuerdosVillaGrupo',
            };
            Object.entries(calls).forEach(e => {
                axios.post(e[1]).then(response => {
                    commit('setDominios', [e[0], response.data])
                    //console.log("response.data", response.data);
                })
            })
        },

        requestIndicador({ commit }, id) {
            /** Cambio producción */
            axios.defaults.baseURL = (location.hostname == 'iamappprefront.iformalia.es') ? 'https://iamappipre.iformalia.es/InnoWSPublico.asmx/' : 'https://iamappi.iformalia.es/InnoWSPublico.asmx/'
            ////axios.defaults.baseURL = 'http://localhost:50351/InnoWSPublico.asmx'

            let data = {
                eParamId: id
            }
            return new Promise((resolve, reject) => {
                axios.post(`RequestIndicador`, data).then(response => {
                    resolve(response.data[0])
                }).catch(function (error) {
                    reject(error);
                    commit('null');
                });
            });
        },

        requestCartaEvolucion({ commit }, id) {
            /** Cambio producción */
            axios.defaults.baseURL = (location.hostname == 'iamappprefront.iformalia.es') ? 'https://iamappipre.iformalia.es/InnoWSPublico.asmx/' : 'https://iamappi.iformalia.es/InnoWSPublico.asmx/'
            ////axios.defaults.baseURL = 'http://localhost:50351/InnoWSPublico.asmx'

            let data = {
                eParamId: id
            }
            return new Promise((resolve, reject) => {
                axios.post(`RequestCartaEvolucion`, data).then(response => {
                    resolve(response.data[0])
                }).catch(function (error) {
                    reject(error);
                    commit('null');
                });
            });
        },

        requestGraficoIndicador({ commit }, id) {
            /** Cambio producción */
            axios.defaults.baseURL = (location.hostname == 'iamappprefront.iformalia.es') ? 'https://iamappipre.iformalia.es/InnoWSPublico.asmx/' : 'https://iamappi.iformalia.es/InnoWSPublico.asmx/'
            ////axios.defaults.baseURL = 'http://localhost:50351/InnoWSPublico.asmx'

            let data = {
                eParamId: id
            }
            return new Promise((resolve, reject) => {
                axios.post(`RequestIndicadorGraficoNuevo`, data).then(response => {
                    resolve(response.data)
                }).catch(function (error) {
                    reject(error);
                    commit('null');
                });
            });
        },



    },
    mutations: {
        setHomeData(state, data) {
            state.homedata = data;
        },
        setHomeCartas(state, data) {
            state.homecartas = data;
        },
        setDominios(state, data) {

            let dominio;

            if (data && data[1].length != 0) {

                //console.log("data[0]", data[0]);
                //console.log("data[1]", data[1]);


                if (data[0] == 'indicadores') {
                    /** Ordeno alfabeticamente por nombre */
                    dominio = data[1].sort((a, b) => a.nombre > b.nombre ? 1 : -1);
                } else {
                    dominio = data[1].sort((a, b) => a.nombre > b.nombre ? 1 : -1);
                }


                if (dominio.some(e => e.carta)) {
                    dominio = dominio.sort((a, b) => a.carta > b.carta ? 1 : -1)
                }
                if (dominio.some(e => e.orden)) {
                    dominio = dominio.sort((a, b) => a.orden < b.orden ? -1 : 1)
                }
                state.dominios[data[0]] = dominio;
            }

        },
        setActuaciones(state, data) {
            state.actuaciones = data;
        },
        setIndicadores(state, data) {
            state.indicadores = data;
        }
    }
};

export default datos;