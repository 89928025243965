import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

Vue.config.productionTip = false


//IDIOMAS
import VueI18n from 'vue-i18n'
import messages from './translations';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: store.state.language,
  messages,
});
//.IDIOMAS

/*AXIOS*/
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

/** Cambio producción */
axios.defaults.baseURL = (location.hostname == 'iamappprefront.iformalia.es') ? 'https://iamappipre.iformalia.es/InnoWSPublico.asmx/' : 'https://iamappi.iformalia.es/InnoWSPublico.asmx/'
////axios.defaults.baseURL = 'http://localhost:50351/InnoWSPublico.asmx'


import * as am5 from "@amcharts/amcharts5";
am5.addLicense("AM5C333277446");

/*AXIOS*/

import { ValidationProvider } from 'vee-validate';
Vue.component('ValidationProvider', ValidationProvider);
import { extend } from 'vee-validate';
import { email, required } from 'vee-validate/dist/rules';
extend('email', email);
extend('required', required);


import VCalendar from 'v-calendar';

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: 'vc',
});


import bannerayudamos from '@/components/parts/te-ayudamos-banner';
Vue.component('banner-ayudamos', bannerayudamos);

import progressbar from '@/components/parts/progress-bar';
Vue.component('progress-bar', progressbar);

import countdown from '@/components/parts/countdown';
Vue.component('count-down', countdown);


import countto from 'vue-count-to';
Vue.component('count-to', countto);

import objetivo from '@/components/parts/objetivo';
Vue.component('objetivo', objetivo);

import estrategia from '@/components/parts/estrategia';
Vue.component('estrategia', estrategia);

import buscador from '@/components/parts/buscador';
Vue.component('buscador', buscador);

import graficapercent from '@/components/parts/grafica-percent';
Vue.component('grafica-percent', graficapercent);

import graficapercentaavv from '@/components/parts/grafica-percent-aavv';
Vue.component('grafica-percent-aavv', graficapercentaavv);

import { ClientTable } from 'vue-tables-2-premium';
Vue.use(ClientTable);

import Multiselect from 'vue-multiselect'
Vue.component('multiselect', Multiselect)



new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
